import { useEffectOnce } from './useEffectOnce'
import { LoggerPayload, useLoggerLegacy } from './useLogger'

function useLogOnMount(eventType: string, eventProperties?: LoggerPayload) {
  const { logEventLegacy } = useLoggerLegacy()
  useEffectOnce(() => {
    logEventLegacy(eventType, eventProperties)
  })
}

export default useLogOnMount
